import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";

const Malopolska = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      seo={{
        title: "Małopolska",
        href: slug,
        lang: "pl",
      }}
      header={{
        background:
          "linear-gradient( 45deg, #22967c, #30af93, #2ABA9A, #30af93, #22967c )",
        icons: "#00AEEB",
        navClass: "malopolska",
        ogImage: require("../../assets/img/portfolio/malopolska_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/malopolska/",
      }}
    >
      <PortfolioHeader name="malopolska" height="82" />
      <section className="container-fluid malopolska_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Małopolska</h1>
              <ul>
                <li>Sesja fotograficzna</li>
                <li>Kampania outdoor</li>
                <li>Kampania Social Media</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner">
              <p>
                Dla Urzędu Marszałkowskiego Województwa Małopolskiego
                realizowaliśmy kampanię promującą program stypendialny
                realizowany dla najzdolniejszych uczniów w Małopolsce.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid malopolska_section_3">
        <div className="row no-gutters align-items-center row_1">
          <div className="col-md-6">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/malopolska_main_1.jpg")}
              alt=""
            />
          </div>
          <div className="col-md-6">
            <div className="inner">
              <h3>Bilboardy</h3>
              <p>
                Na potrzeby realizacji zorganizowaliśmy sesję zdjęciową w której
                wzięli udział beneficjenci organizowanego przez Urząd
                Marszałkowski Regionalnego Programu Stypendialnego. Ukazanie
                prawdziwie najzdolniejszych uczniów z Małopolski było jednym z
                głównych założeń prowadzonych działań, dzięki czemu uczniowie
                pojawili się na wszystkich materiałach promocyjnych
                wykorzystywanych w kampanii.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid malopolska_section_4">
        <div className="row row_1">
          <div className="col-md-5 text-col">
            <div className="inner">
              <h3>Citylighty</h3>
              <p>
                Przygotowane przez nas kreacje rozświetlały też przestrzeń
                publiczną z licznych citylightów, na których mogliśmy dostrzec
                wyróżnionych za osiągnięcia w nauce młodych Małopolan.
              </p>
            </div>
          </div>
          <div className="col-md-5 offset-md-2 img-col">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/malopolska_main_2.jpg")}
              alt=""
            />
            <img
              className="img-fluid img-absolute"
              src={require("../../assets/img/portfolio/malopolska_main_3.jpg")}
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="malopolska_section_5">
        <div className="row row_1">
          <div className="col-md-5 img-col">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/malopolska_main_4.jpg")}
              alt=""
            />
          </div>
          <div className="col-lg-5 col-md-7 offset-lg-1 text-col">
            <div className="inner">
              <h3>Referencje</h3>
              <p>
                Kampania zakończyła się za sukcesem, a od Urzędu
                Marszałkowskiego Województwa Małopolskiego otrzymaliśmy
                rekomendujące nas referencje.
              </p>
            </div>
          </div>
        </div>
        <div className="baner">
          <img
            className="img-fluid baner-img"
            src={require("../../assets/img/portfolio/malopolska_baner.jpg")}
            alt=""
          />
        </div>
      </section>

      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Malopolska;
